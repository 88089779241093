<template>
	<main class="vcc-main">
		<router-link :to="{ name: 'OrganizationPage', params: { id: id } }">← Назад</router-link><br><br>
		<h1 class="heading heading__hr">Редактировать</h1>
		<organization-registry-form :form-data="getRecord" :select-options="getFields" mode="edit" @submit="submitForm" @restartPage="restartPage"/>
	</main>
</template>

<script>
import OrganizationRegistryForm from '@/components/forms/OrganizationRegistryForm.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
	components: { OrganizationRegistryForm },
	props: {
		id: {
			type: [String, Number],
			required: true,
		},
	},
	name: 'EditOrganization',
	computed: mapGetters(['getRecord', 'getFields']),
	methods: {
		...mapActions(['fetchRecord', 'fetchFields', 'updateRecord']),
		submitForm(data) {
			this.updateRecord(data);
		},
		restartPage() {
			this.fetchRecord(this.id)
		}
	},
}
</script>
